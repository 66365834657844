import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import Layout from '../../components/Layout';

export default function TermsAndConditions() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <Container maxWidth="lg">
        <div
          name="termly-embed"
          data-id="e59aa006-fd9c-4c52-a000-909c4cb0c0f4"
          data-type="iframe"
        />
      </Container>
    </Layout>
  );
}
